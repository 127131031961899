import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
  })

  export class ThemeSettingsService {
    constructor(
        private http: HttpClient,
      ) {}

      updateThemeSetting(params: any) {
        let httpHeaders = { showLoader: "true" };
        return this.http.post("user/settings", params, { headers: httpHeaders }).pipe(
          map((resp: any) => {
            return resp;
          })
        );
      }
  }
