<!-- !Breadcrumbs -->
<!-- !In routes add configuration to show the  breadcrumbs in the view -->
@if (breadcrumbs.length > 1) {
<div class="flex flex-wrap items-center font-medium px-2 py-1">
    <ng-container *ngFor="let bc of breadcrumbs; let last = last">
      <div class="flex items-center whitespace-nowrap gap-1 mr-1" *ngIf="!last">
        <a class="whitespace-nowrap text-primary-500 text-sm leading-5 rounded hover:underline hover:text-blue-500" [routerLink]="bc.link">{{ bc.label }}</a>
        <mat-icon
          class="text-secondary icon-size-5"
          [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
      </div>
      <div class="flex items-center whitespace-nowrap gap-1 mr-1" *ngIf="last">
        <span class="text-secondary text-sm">{{ bc.label }}</span>
      </div>
    </ng-container>
</div>
}