import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private socket: Socket;
  private serverUrl: string = environment.socketUrl; 
  constructor(private user:UserService,
    private auth:AuthService
  ) {
    this.socket = io(this.serverUrl,{
      extraHeaders: {
        Authorization: `Bearer ${this.auth.accessToken}`,  // Send JWT token in Authorization header
      }
    });
    this.user.user$.subscribe((data)=>{
      this.registerUserSocket(data.id);
    })
  }

  private registerUserSocket(userId: string) {
    console.log("Register User To Socket.")
    this.socket.emit('REGISTER_USER', userId);
  }

  getNotifications(): Observable<string> {
    return new Observable((observer) => {
      this.socket.on('NEW_NOTIFICATION', (noti: any) => {
        this.requestPermission().then(permission => {
          if (permission === 'granted') {
            this.triggerNotification(noti.type, {
              body: noti.message,
              icon: 'images/logo/logo.svg',
            });
          } else {
            console.log('Notification permission denied');
          }
        }).catch(error => {
          console.error('Error requesting notification permission:', error);
        });
        observer.next(noti);
      });
    });
  }

  sendAcknowledgment(message: string) {
    this.socket.emit('acknowledge', message);
  }

   // Check if notifications are supported
   isNotificationSupported(): boolean {
    return 'Notification' in window;
  }

  // Request permission to show notifications
  requestPermission(): Promise<NotificationPermission> {
    return new Promise((resolve, reject) => {
      if (this.isNotificationSupported()) {
        Notification.requestPermission().then((permission) => {
          resolve(permission);
        }).catch((err) => reject(err));
      } else {
        reject('Notification API not supported in this browser.');
      }
    });
  }

  // Trigger a notification
  triggerNotification(title: string, options: NotificationOptions): void {
    if (this.isNotificationSupported() && Notification.permission === 'granted') {
      new Notification(title, options);
    } else {
      console.error('Permission not granted or notifications not supported.');
    }
  }


}
