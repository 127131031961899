import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Shortcut } from 'app/layout/common/shortcuts/shortcuts.types';
import { environment } from 'environments/environment';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShortcutsService {
    constructor(private http: HttpClient) { }

    baseUrl = environment.apiUrl;


    getAllShortcuts(): Observable<any> {
        return this.http.get(`shortcut`).pipe(
            map((resp: any) => resp.data)
        );
    }

    createShortcut(data: any): Observable<any> {
        return this.http.post(`shortcut`, data);
    }


    deleteShortcut(id: number) {
        return this.http.delete(`shortcut/${id}`).pipe(
            map((resp: any) => {
                return resp;
            })
        );
    }

    updateShortcut(id: number, data: any): Observable<any> {
        return this.http.patch(`shortcut/${id}`, data);
    }
}
