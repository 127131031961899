import { customSnackbarConfig } from 'app/shared/model/snackbar.interface';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule, NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import {
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { Shortcut } from 'app/layout/common/shortcuts/shortcuts.types';
import { Subject, takeUntil } from 'rxjs';
import { CustomSnackbarService } from 'app/shared/components/snackbar/snackbar.service';

@Component({
    selector: 'shortcuts',
    templateUrl: './shortcuts.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'shortcuts',
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        NgClass,
        NgTemplateOutlet,
        RouterLink,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        CommonModule
    ],
})
export class ShortcutsComponent implements OnInit, OnDestroy {
    @ViewChild('shortcutsOrigin') private _shortcutsOrigin: MatButton;
    @ViewChild('shortcutsPanel') private _shortcutsPanel: TemplateRef<any>;

    mode: 'view' | 'modify' | 'add' | 'edit' = 'view';
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    shortcuts: any[] = [];
    shortcutForm!: FormGroup;
    shortcutIdForEdit: string | null = null

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _shortcutsService: ShortcutsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private customSnackBar: CustomSnackbarService

    ) { 
         // Initialize the form
         this.shortcutForm = this._formBuilder.group({
            label: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
            description: ['', Validators.maxLength(20)],
            link: ['', Validators.required],
            useRouter: [false],
        });
    }


    ngOnInit(): void {
        this.loadShortcuts();
       

    }


    loadShortcuts(): void {
        this._shortcutsService.getAllShortcuts().subscribe(
            (data) => {
                this.shortcuts = data;
            },
            (error) => {
                console.error('Error fetching shortcuts', error);
            }
        );
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the shortcuts panel
     */
    openPanel(): void {
        // Return if the shortcuts panel or its origin is not defined
        if (!this._shortcutsPanel || !this._shortcutsOrigin) {
            return;
        }

        // Make sure to start in 'view' mode
        this.mode = 'view';

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._shortcutsPanel, this._viewContainerRef)
        );
    }

    /**
     * Close the shortcuts panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Change the mode
     */
    changeMode(mode: 'view' | 'modify' | 'add' | 'edit'): void {
        // Change the mode
        this.mode = mode;
    }

    /**
     * Prepare for a new shortcut
     */
    newShortcut(): void {
        // Reset the form
        this.shortcutForm.reset();

        // Enter the add mode
        this.mode = 'add';
    }

    /**
     * Edit a shortcut
     */
    editShortcut(shortcut: Shortcut): void {
        // Reset the form with the shortcut
        this.shortcutForm.reset(shortcut);

        // Enter the edit mode
        this.mode = 'edit';
        if (shortcut.id) {
            this.shortcutIdForEdit = shortcut.id
        }

    }



    save(): void {
        if (!this.shortcutForm.valid) {
            return;
        }
        const shortcutData = this.shortcutForm.value;
        console.log("shortcutData", shortcutData);

        if (this.mode === 'add') {
            shortcutData.useRouter = shortcutData.useRouter == null  ? false : shortcutData.useRouter;
            this._shortcutsService.createShortcut(shortcutData).subscribe(
                () => {
                    this.loadShortcuts();
                    this.shortcutForm.reset();
                    this.changeMode('view');
                    this.customSnackBar.showSuccess('Shortcut is created')
                },
                (error) => {
                    this.customSnackBar.showError('Shortcut is Not created')
                    
                }
            );
        } else if (this.mode === 'edit') {
            const shortcutId = shortcutData.id;
            console.log("Editing ID:", shortcutId);

            const updateData = {
                label: shortcutData.label,
                description: shortcutData.description,
                link: shortcutData.link,
                useRouter: shortcutData.useRouter,
            };

            if (this.shortcutIdForEdit) {
                this._shortcutsService.updateShortcut(Number(this.shortcutIdForEdit), updateData).subscribe(
                    () => {
                        this.loadShortcuts();
                        this.shortcutForm.reset();
                        this.changeMode('view');
                    this.customSnackBar.showSuccess('Shortcut is Updated')

                    },
                    (error) => {
                        this.customSnackBar.showError('Shortcut is Not Updated')

                    }
                );
            }
        }
    }



    delete(id: number) {
        if (this.shortcutIdForEdit) {
            this._shortcutsService.deleteShortcut(Number(this.shortcutIdForEdit)).subscribe(
                (result) => {
                    console.log('Shortcut deleted:', result);
                    this.loadShortcuts();
                    this.shortcutForm.reset();
                    this.changeMode('view');
                    this.customSnackBar.showSuccess(result.message)
                },
                (error) => {
                    this.customSnackBar.showError(error)
                    console.error('Error deleting shortcut:', error);
                }
            );
        }
    }




    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(
                    this._shortcutsOrigin._elementRef.nativeElement
                )
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }
}
