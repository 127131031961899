import { messages } from './../../mock-api/apps/chat/data';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { CustomSnackbarService } from 'app/shared/components/snackbar/snackbar.service';
import { catchError, Observable, of, ReplaySubject, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user/user.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private _snackBar =inject(CustomSnackbarService)
    private _httpClient = inject(HttpClient);
    private _authService = inject(AuthService)
    private _userService = inject(UserService)
    private _router = inject(Router)
    private _navigation: ReplaySubject<Navigation> =
        new ReplaySubject<Navigation>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        // return this._httpClient.get<Navigation>('api/common/navigation').pipe(
        //     tap((navigation) => {
        //         console.log('navigation :', navigation);
        //         this._navigation.next(navigation);
        //     })
        // );
        return this._httpClient.get<any>('modules/module-data').pipe(
            catchError((err:any)=>{
                this._snackBar.showError(err.error.message)
                return of({data:null})
            }),
            tap((navigation) => {
                let fullNavigation = {
                    compact: (navigation.data) as FuseNavigationItem[],
                    default: [],
                    futuristic: [],
                    horizontal: []
                }
                this._navigation.next(fullNavigation);
            })
        );
    }
}
