<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark:bg-gray-900 print:hidden"
    [appearance]="'compact'"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation?.compact"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="mb-4 mt-3 flex h-20 items-center justify-center">
            <img class="w-10" src="images/logo/logo.svg" alt="Logo image" />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="w-full min-w-0 flex-auto">
    <!-- Header -->
    <div
        class="bg-card relative z-49 flex py-0.5 w-full flex-0 items-center shadow dark:border-b dark:bg-transparent dark:shadow-none print:hidden px-2">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <!-- Email -->
            <button class="mr-2" mat-icon-button [routerLink]="['apps/mailbox']">
                <mat-icon>email</mat-icon>
            </button>

            <!-- <languages></languages> -->
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!-- <search [appearance]="'bar'"></search> -->
            <shortcuts></shortcuts>
            <!-- <messages></messages> -->
            <notifications></notifications>
            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()"
            >
                <mat-icon
                    [svgIcon]="'heroicons_outline:chat-bubble-left-right'"
                ></mat-icon>
            </button> -->

            <!-- Settings -->
            @if(this.user?.userTypeId === this.superAdmin){
                <button [matMenuTriggerFor]="settings" class="mr-2" mat-icon-button>
                    <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'" ></mat-icon>
                </button>
            }

            <!-- users -->
            <user></user>
        </div>
    </div>
    <div class="flex flex-col overflow-y-auto" style="height: calc(100% - 44px);">
        <app-breadcrumb></app-breadcrumb>
        <!-- Content -->
        <div class="flex flex-auto flex-col">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            @if (true) {
                <router-outlet></router-outlet>
            }
        </div>
    </div>

    <!-- Footer -->
    <!-- <div
        class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-start border-t px-4 dark:bg-transparent md:px-6 print:hidden"
    >
        <span class="text-secondary font-medium"
            >TPS &copy; {{ currentYear }}</span
        >
    </div> -->
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
<mat-menu class="user-status-menu" #settings="matMenu">
    <button mat-menu-item class="min-h-fit py-2" [routerLink]="['users/list']">
        <mat-icon [svgIcon]="'heroicons_outline:user'" class="mr-2 min-h-5 h-5 min-w-5 w-5"></mat-icon>
        <span class="text-dark dark:text-gray-300 text-base font-segoeui">User</span>
    </button>
    <button mat-menu-item class="min-h-fit py-2" [routerLink]="['teams/list']">
        <mat-icon [svgIcon]="'heroicons_outline:user-group'" class="mr-2 min-h-5 h-5 min-w-5 w-5"></mat-icon>
        <span class="text-dark dark:text-gray-300 text-base font-segoeui">Teams</span>
    </button>
    <button mat-menu-item class="min-h-fit py-2" [routerLink]="['/modules']">
        <mat-icon [svgIcon]="'heroicons_outline:inbox-stack'" class="mr-2 min-h-5 h-5 min-w-5 w-5"></mat-icon>
        <span class="text-dark dark:text-gray-300 text-base font-segoeui">Modules</span>
    </button>
    <button mat-menu-item class="min-h-fit py-2" [routerLink]="['/role-permission-list']">
        <mat-icon [svgIcon]="'heroicons_outline:inbox-stack'" class="mr-2 min-h-5 h-5 min-w-5 w-5"></mat-icon>
        <span class="text-dark dark:text-gray-300 text-base font-segoeui">Role Permission</span>
    </button>
    <!-- <button mat-menu-item [routerLink]="['users/list']">
        <i class="fa-regular fa-user mr-2 h-4 w-6 flex justify-center"></i>
        <span >User</span>
    </button>
    <button mat-menu-item [routerLink]="['teams/list']">
        <i class="fa-regular fa-users mr-2 h-4 w-6 flex justify-center"></i>
        <span>Teams</span>
    </button>
    <button mat-menu-item [routerLink]="['/modules']">
        <i class="fa-regular fa-network-wired mr-2 h-4 w-6 flex justify-center"></i>
        <span>Modules</span>
    </button>
    <button mat-menu-item [routerLink]="['/role-permission-list']">
        <i class="fa-regular fa-network-wired mr-2 h-4 w-6 flex justify-center"></i>
        <span>Role Modules</span>
    </button> -->
</mat-menu>