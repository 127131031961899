import { CommonModule, JsonPipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MaterialModule } from 'app/modules/material/material.module';
import { NgPrimeModule } from 'app/modules/ng-prime/ng-prime.module';
import { BreadcrumbService } from './breadcrumb.service';
 export interface Breadcrumb {
    label: string;
    link: string;
  }
  
@Component({
    selector: 'app-breadcrumb',
    standalone: true,
    imports: [CommonModule, NgPrimeModule, MaterialModule , JsonPipe],
    templateUrl: './breadcrumb.component.html',
    styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent {

    breadcrumbs: Breadcrumb[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute,private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.breadcrumb$.subscribe((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.breadcrumbs = [];  
        this.buildBreadcrumb(this.activatedRoute.root); 
      }
    });
  }

  ngOnInit(): void {
    this.buildBreadcrumb(this.activatedRoute.root);
  }
  private buildBreadcrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): void {
    const children: ActivatedRoute[] = route.children;
  
    if (children.length === 0) {
      return;  
    }
  
    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
  
      if (routeURL !== '') {
        url += `/${routeURL}`; 
  
        if (child.snapshot.data.breadcrumb) {
          const breadcrumbLabel = child.snapshot.data.breadcrumb.label;
  
          if (!breadcrumbs.some(b => b.label === breadcrumbLabel)) {
            breadcrumbs.push({
              label: breadcrumbLabel,
              link: url,
            });
          }
        }
      }
  
      this.buildBreadcrumb(child, url, breadcrumbs); 
    }
    
    if (route.parent && route.parent.snapshot.data.breadcrumb) {
      const parentUrl = '/' + route.parent.snapshot.url.map(segment => segment.path).join('/');
      const parentLabel = route.parent.snapshot.data.breadcrumb.label;
  
      if (!breadcrumbs.some(b => b.label === parentLabel)) {
        breadcrumbs.unshift({
          label: parentLabel,
          link: parentUrl,
        });
      }
    }
  
    this.breadcrumbs = breadcrumbs;
  }
}
