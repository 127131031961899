import { SettingsComponent } from './../../../common/settings/settings.component';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router, RouterModule, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseUtilsService } from '@fuse/services/utils';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { environment } from 'environments/environment';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { UserType } from './user.enum';
import { BreadcrumbComponent } from "../../../../shared/components/breadcrumb/breadcrumb.component";
import { NotificationsService as SocketNotificationService } from 'app/socket/notifications.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';

@Component({
    selector: 'thin-layout',
    templateUrl: './thin.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
    FuseLoadingBarComponent,
    FuseVerticalNavigationComponent,
    MatButtonModule,
    MatIconModule,
    LanguagesComponent,
    FuseFullscreenComponent,
    SearchComponent,
    ShortcutsComponent,
    MessagesComponent,
    NotificationsComponent,
    UserComponent,
    RouterOutlet,
    QuickChatComponent,
    SettingsComponent,
    MatMenuModule,
    RouterModule,
    BreadcrumbComponent
],
})
export class ThinLayoutComponent implements OnInit, OnDestroy {
    private subscription = new Subscription()
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    loggedInUsersCount!: number;

    user: any;
    superAdmin:number



    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _utilsService: FuseUtilsService,
        private cdr: ChangeDetectorRef,
        private userService: UserService,
        private _socketNotificationService:SocketNotificationService,
        private _notification:NotificationsService,
    ) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.subscription.add(this._navigationService.get().subscribe())
        this.subscription.add(this._notification.getAll().subscribe())
        this.userService.user$.subscribe(
            (user: any) => {
              this.user = user;
            },
          );
          this.superAdmin = UserType.superAdmin
          this._socketNotificationService.getNotifications().subscribe(res=>{
            this._notification.getAll().subscribe()
          })
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
            const eventSource = new EventSource(environment.apiUrl + 'auth/logged-in');
            eventSource.onmessage = ({ data }) => {
              if (JSON.parse(data) && JSON.parse(data).length > 0) {
                this.fetchLoggedInUsersCount();
              }
            }
            this.fetchLoggedInUsersCount();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.subscription.unsubscribe()
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    fetchLoggedInUsersCount() {
        this._utilsService.getLoggedInUsersCount().subscribe((data: Array<any>) => {
            if (Array.isArray(data) && data.length > 0) {
                this.loggedInUsersCount = data.length;
                this.cdr.detectChanges();
            }
        });
    }
}
