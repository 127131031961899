import { NgClass } from '@angular/common';
import { Component, ViewChild, ElementRef, OnDestroy, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import {
    FuseConfig,
    FuseConfigService,
    Scheme,
    Theme,
    Themes,
} from '@fuse/services/config';

import { Subject, takeUntil } from 'rxjs';
import { ThemeSettingsService } from './settings.service';
import { CommonService } from 'app/modules/common/common.service';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomSnackbarService } from 'app/shared/components/snackbar/snackbar.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'app/shared/components/snackbar/snackbar.component';
import { CustomDialogComponent } from 'app/shared/components/custom-dialog/custom-dialog.component';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styles: [
        `
            settings {
                position: static;
                display: block;
                flex: none;
                width: auto;
            }

            @media (screen and min-width: 1280px) {
                empty-layout + settings .settings-cog {
                    right: 0 !important;
                }
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        MatIconModule,
        FuseDrawerComponent,
        MatButtonModule,
        NgClass,
        MatTooltipModule,
        MatDialogModule,
        SnackbarComponent,
        NgClass,
        MatSnackBarModule,
        CustomDialogComponent
    ],
})
export class SettingsComponent implements OnInit, OnDestroy {
    @ViewChild(CustomDialogComponent) customDialog!: CustomDialogComponent;
    @ViewChild('settingsDrawer') settingsDrawer: FuseDrawerComponent;

    config: FuseConfig;
    layout: string;
    scheme: 'dark' | 'light';
    theme: string;
    themes: Themes;
    settingsData: any;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public isLoggedIn = true;
    @ViewChild('settingsButton') settingsButton!: ElementRef;
    public noAuthNeededRoute: Array<string> = [
        "/sign-in",
        "/sign-out",
        "/forgot-password",
        "/reset-password",
    ];

    public settingsDrawerOpened = false;
    private lastOpenedState = false;
    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _fuseConfigService: FuseConfigService,
        private themeSettingService: ThemeSettingsService,
        private _settingsStatus: CommonService,
        private customSnackBarService: CustomSnackbarService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._router.events.subscribe(() => {
            if (this.noAuthNeededRoute.includes(this._router.url)) {
                this.isLoggedIn = false
            } else {
                this.isLoggedIn = true
            }
        })
        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: FuseConfig) => {
                this.config = config
            });
        this._settingsStatus.dynamicStaticSettings.subscribe(data => {
            if (data) {
                this.settingsButton.nativeElement.click();
            }
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the layout on the config
     *
     * @param layout
     */
    setLayout(layout: string): void {
        // Clear the 'layout' query param to allow layout changes
        this._router
            .navigate([], {
                queryParams: {
                    layout: null,
                },
                queryParamsHandling: 'merge',
            })
            .then(() => {
                // Set the config
                this._fuseConfigService.config = { layout };
            });
        // localStorage.setItem('lastSelectedLayout', layout);
    }

    /**
     * Set the scheme on the config
     *
     * @param scheme
     */
    setScheme(scheme: Scheme): void {
        this._fuseConfigService.config = { scheme };
        // localStorage.setItem('lastSelectedScheme', scheme);
    }

    /**
     * Set the theme on the config
     *
     * @param theme
     */
    setTheme(theme: Theme): void {
        this._fuseConfigService.config = { theme };
        // localStorage.setItem('lastSelectedTheme', theme);
    }

    toggleSettingsDrawer(): void {
        this.settingsDrawerOpened = !this.settingsDrawerOpened;
    }

    /**
     * This function is called whenever the drawer's opened state changes
     * @param opened - the new state of the drawer
     */
    onSettingsDrawerOpenedChange(opened: boolean): void {
        this.settingsDrawerOpened = opened;

        if (opened === this.lastOpenedState) {
            return;
        }
        this.lastOpenedState = opened;
        // this.settingsDrawerOpened = opened; 

        if (!opened) {
            this.saveSettings();
        }
    }

    saveSettings() {
        if (this.config) {
            const params = {
                themeSettings: {
                    theme: this.config.theme,
                    scheme: this.config.scheme,
                    layout: this.config.layout
                }
            };

            this.themeSettingService.updateThemeSetting(params).subscribe((resp) => {
                if (resp) {
                    localStorage.setItem('lastSelectedTheme', this.config.theme);
                    localStorage.setItem('lastSelectedLayout', this.config.layout);
                    localStorage.setItem('lastSelectedScheme', this.config.scheme);
                } else {
                    this.customSnackBarService.showError('Failed to update theme settings.');
                }
            });
        }
    }

    resetToLastSelectedSettings() {
        // this.setTheme(localStorage.getItem('lastSelectedTheme'))
        // this.setLayout(localStorage.getItem('lastSelectedLayout'))
        // this.setScheme(localStorage.getItem('lastSelectedScheme'))
        this.settingsDrawerOpened = false;
    }
}
