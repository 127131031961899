<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        @if (showAvatar && user?.avatar) {
        <!-- <img class="h-7 w-7 rounded-full" [src]="user.avatar || defaultAvatar"  alt="No image!"/> -->
        }
        @if (!showAvatar || !user.avatar) {
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        }
        <span class="absolute bottom-0 right-0 h-2 w-2 rounded-full" [ngClass]="{
                'mb-px mr-px': !showAvatar || !user.avatar,
                'bg-green-500': user?.status === 'online',
                'bg-amber-500': user?.status === 'away',
                'bg-red-500': user?.status === 'busy',
                'bg-gray-400': user?.status === 'not-visible',
            }"></span>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <div class="mat-mdc-menu-item-text">
        <div class="top-head flex items-center justify-start gap-3 px-4 pb-2 border-bottom">
            <div class="h-12 w-12 rounded-full overflow-hidden border-2 border-gray-300 dark:border-gray-600">
                <div class="h-full w-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center">
                    <mat-icon [svgIcon]="'heroicons_outline:user'"></mat-icon>
                </div>
                <!-- <div class="h-full w-full flex items-center justify-center">
                    <img src="https://img.dummy-image-generator.com/people/dummy-400x400-CharlesBaudelaire.jpg" alt="_profile_img" class="h-full w-full" srcset="">
                </div> -->
            </div>
            <div class="flex flex-col items-start gap-2">
                <span class="text-dark dark:text-gray-300 text-2xl leading-none tracking-normal">Hi,&nbsp;{{ this.user?.firstName }}</span>
                <!-- <span class="text-gray-700 text-base leading-none tracking-normal">
                    <i class="fa-regular fa-bell text-gray-700 dark:text-gray-400 mr-2"></i>
                    <span class="text-gray-700 dark:text-gray-400">You have 
                        <span class="bg-primary py-0.5 px-2 rounded-full text-gray-100 tracking-normal text-sm font-medium">2</span> 
                        new messages and 
                        <span class="bg-primary py-0.5 px-2 rounded-full text-gray-100 tracking-normal text-sm font-medium">15</span> 
                        new tasks
                    </span>
                </span> -->
            </div>

            <!-- userCategoryid ==1  -->
            <div class="text-base bg-primary p-1 px-2 rounded-full text-gray-100 leading-none tracking-normal ml-5">{{this.user?.userCategory?.name}}  {{ this.user?.userType.name }}</div>
        </div>
        <div class="border-bottom">
            <button mat-menu-item [routerLink]="['/my-profile']">
                <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
                <span class="text-dark dark:text-gray-300">Profile</span>
            </button>
            <div class="hasSubmenu">
                <button mat-menu-item class="pr-0" (click)="showSubmenus($event);">
                    <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
                    <span class="text-dark dark:text-gray-300 flex-grow justify-between">Settings
                        <mat-icon [svgIcon]="'heroicons_outline:chevron-down'" class="m-0 duration-200" [ngClass]="{'rotate-180':showSubmenu}"></mat-icon>
                    </span>
                </button>
                <div class="subMenuPanel" style="--mat-menu-item-icon-size:18px;" [ngClass]="{'block':showSubmenu,'hidden':!showSubmenu,}">
                    <button mat-menu-item class="min-h-fit py-2 pl-14">
                        <mat-icon [svgIcon]="'heroicons_outline:user'" class="mr-2 min-h-5 min-w-5"></mat-icon>
                        <span class="text-dark dark:text-gray-300 text-base">Account Settings</span>
                    </button>
                    <button mat-menu-item class="min-h-fit py-2 pl-14">
                        <mat-icon [svgIcon]="'heroicons_outline:bell-alert'" class="mr-2 min-h-5 min-w-5"></mat-icon>
                        <span class="text-dark dark:text-gray-300 text-base">Notification Settings</span>
                    </button>
                    <button mat-menu-item class="min-h-fit py-2 pl-14" (click)="sendData()">
                        <mat-icon [svgIcon]="'heroicons_outline:swatch'" class="mr-2 min-h-5 min-w-5"></mat-icon>
                        <span class="text-dark dark:text-gray-300 text-base">Theme Settings</span>
                    </button>
                </div>
            </div>
        </div>
        <button mat-menu-item (click)="signOut()">
            <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
            <span>Sign out</span>
        </button>
    </div>
    <!-- <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{ this.user?.firstName }} {{ this.user?.lastName }}</span>
        </span>
    </button> -->
    <!-- <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button> -->
    <!-- <button mat-menu-item [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"></mat-icon>
        <span>Status</span>
    </button> -->
    <!-- <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Sign out</span>
    </button> -->
</mat-menu>
<mat-menu class="user-status-menu" #settings="matMenu">
    <button mat-menu-item [routerLink]="['users/list']">
        <span class="mr-3 inline-flex h-4 w-4 "></span>
        <span >User</span>
    </button>
    <button mat-menu-item [routerLink]="['teams/list']">
        <span class="mr-3 inline-flex h-4 w-4 "></span>
        <span>Teams</span>
    </button>
    <button mat-menu-item [routerLink]="['/modules']">
        <span class="mr-3 inline-flex h-4 w-4 "></span>
        <span>Modules</span>
    </button>
    <button mat-menu-item [routerLink]="['role-permission-list']">
        <span class="mr-3 inline-flex h-4 w-4 "></span>
        <span>Role Mapping</span>
    </button>


</mat-menu>
<mat-menu class="user-status-menu" #userStatus="matMenu">
    <button mat-menu-item (click)="updateUserStatus('online')">
        <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('away')">
        <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('busy')">
        <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('not-visible')">
        <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>